import { gql } from "urql";

export const PALLET_WAREHOUSE_QUERY = `
  query {
    palletWarehousePage {
      data {
        attributes {
          Content {
            title
            description
          }
        }
      }
    }
  
    palletWarehouses(pagination: { limit: 100 },  sort: "id:asc") {
      data {
        id
        attributes {
          title
          description
          price
          program
          loadType
          palletCount
          shipsFrom
		  image{
			data {
				attributes {
				url
				previewUrl
				}
			}
			}
        }
      }
    }
  }
`;

export const FAQ_ALL_QUERY = `
	query { 
		faqs {
			data {
				id
				attributes {
				question
				answer
				category
				iframe
				}
			}
		}
	}
`;

export const RESOURCES_QUERY_ITEM = `
	query {
		resourcesPage{
			data {
				attributes {
					title_header
					title_description
					banner_bottom {
						title_header
						button_name
						banner_link
						body_description
					}
					meta_data {
						primary_KW
						secondary_KW
						meta_title
						meta_description
					}
					body_description
					EBook {
						title_header
						title_description
						link_PDF_book
						pictures {
							data {
								attributes {
									alternativeText
									url
								}
							}
						}
						body_header
						body_description
						author
						year
						link_name
					}
				}
			}
		}

		resourcesPageItems(pagination: { limit: 100 }, sort: "publishedAt:desc") {
			data {
				id
				attributes {
					publishedAt
					title_header
					title_description
					title_pictures {
						data {
							attributes {
								alternativeText
								url
							}
						}
					}
					title_link_pdf_documents
					url
					H1_Page_Title
					page_description
					author {
						data {
						  attributes {
							alternativeText
							url
						  }
						}
					}
					createdAt
					author_name
					page_pictures {
						data {
							attributes {
								alternativeText
								url
							}
						}
					}
					banner_bottom {
						title_header
						button_name
						banner_link
						body_description
					}
					meta_data {
						primary_KW
						secondary_KW
						meta_title
						meta_description
					}
					Type
				}
			}
		}
	}
`;

//

export const META_DATA_FRAGMENT = `
	fragment metaData on ComponentMetaDataSeoResoursePageData {
		primary_KW
		secondary_KW
		meta_title
		meta_description
	}
`;

export const BANER_BOTTOM_FRAGMENT = `
	fragment bannerBottom on ComponentBannerBannerBottomOfPage {
		title_header
		button_name
		banner_link
		body_description
	}
`;

export const STATE_QUERY_PAGE = `
	query StatePage {
		statePage {
			data {
				attributes {
					page_title_h1
					page_description
					secont_body_title
					banner_bottom {
						... bannerBottom
					}
					meta_data {
						... metaData
					}
				}
			} 
		}
	}
	${BANER_BOTTOM_FRAGMENT}
	${META_DATA_FRAGMENT}
`;

export const STATE_QUERY_PAGE_ITEMS = `
	query StatePageItems {
		stateCollections(pagination: { limit: 50 }) {
			data {
				attributes {
					state_ID
					url
					page_title_h1
					page_description
					secont_body_title
					pictures {
						data {
						id
							attributes {
								url
								alternativeText
							}
						} 
					}
					map_title
					map_description
					banner_bottom {
						...bannerBottom
					}
					meta_data {
						...metaData
					}
				}
			} 
		}
	}
	${BANER_BOTTOM_FRAGMENT}
	${META_DATA_FRAGMENT}
`;

export const FAQ_QUERY = `
  query {
    faqs(sort:"id:asc") {
      data {
        id
        attributes {
          question
          answer
          category
          iframe
        }
      }
    }
  }
`;

export const FAQ_BY_CATEGORY_QUERY = `
  query Faqs($category: String!) {
    faqs(filters: { category: { eq: $category } }) {
      data {
        id
        attributes {
          question
          answer # Fetch the entire answer as a JSON object
          category
          iframe
        }
      }
    }
  }
`;

export const CATEGORIES_PAGE = `
	query CategoriesPage {
		categoriesPage {
			data {
				attributes {
					page_title_h1
					page_description
					banner_bottom {
						...bannerBottom
					}
					meta_data {
						...metaData
					}
				}
			}
		}
	}
	${BANER_BOTTOM_FRAGMENT}
	${META_DATA_FRAGMENT}
`;

export const CATEGORIES_PAGE_ITEMS = `
	query CategoriesPageItems {
		categoriesCollections(pagination: { limit: 150 }) {
			data {
				id
				attributes {
					page_title_h1
					page_description
					url
					avatar {
						data {
							attributes {
								url
							}
						}
					}
					type
					category
					meta_data {
						...metaData
					}
				}
			}
		}
	}
	${META_DATA_FRAGMENT}
`;
