import {
	createContext,
	ReactNode,
	useState,
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
} from "react";
import { useQuery } from "urql";
import {
	CATEGORIES_PAGE,
	CATEGORIES_PAGE_ITEMS,
	FAQ_ALL_QUERY,
	PALLET_WAREHOUSE_QUERY,
	RESOURCES_QUERY_ITEM,
	STATE_QUERY_PAGE,
	STATE_QUERY_PAGE_ITEMS,
} from "../../graphql/clientStrapio.query";
import { GET_STATIC_CONTEXT } from "./static-context.query";
import {
	Category_FragmentFragment,
	GetStaticContextQuery,
	GetStaticContextQueryVariables,
	Retailer_FragmentFragment,
	State_FragmentFragment,
	WhiteLabelConfig_FragmentFragment,
	VocabularyEntry_FragmentFragment,
} from "./static-context.query.graphql.module";

interface Props {
	children: ReactNode;
	host: string;
}

export interface IRegion {
	title: string;
	value: string;
}

interface StaticContext {
	faqs: any[];
	host: string;
	palletWarehouseItems: any;
	palletWarehousePageContent: any;
	retailers: Retailer_FragmentFragment[];
	retailerTranslator: (retailerId: string) => string;
	vocabularyEntries: VocabularyEntry_FragmentFragment[];
	getVocabularySortedAlphabetically: () => VocabularyEntry_FragmentFragment[];
	getVocabularyEntry: (
		vocabularyEntryId: string | undefined,
	) => VocabularyEntry_FragmentFragment | undefined;
	translator: (vocabularyEntryId: string) => string;
	categories: Category_FragmentFragment[];
	getCategory: (categoryId: string) => Category_FragmentFragment | undefined;
	getLinkedCategories: (categoryId: string) => Category_FragmentFragment[];
	getLinkedCategoriesByIds: (
		categoryIds: string[],
	) => Category_FragmentFragment[];
	getCategoriesByIds: (categoryIds: string[]) => Category_FragmentFragment[];
	getCategoryByKeyword: (
		categoryKeyword: string,
	) => Category_FragmentFragment | undefined;
	translateCategory: (categoryId: string) => string;
	categoryIsParent: (categoryId: string) => boolean;
	states: State_FragmentFragment[];
	regions: IRegion[];
	getState: (stateId: string) => State_FragmentFragment | undefined;
	getStateByAbbreviation: (
		stateId: string,
	) => State_FragmentFragment | undefined;
	whiteLabelConfig: WhiteLabelConfig_FragmentFragment | null | undefined;
	isHidden: boolean;
	setIsHidden: Dispatch<SetStateAction<boolean>>;
	statePageStrapio?: any;
	getStateStrapio?: any;
	statePageStrapioItems?: any;
	categoriesPageStrapio?: any;
	categoriesPageStrapioItems?: any;
	getCategoriesStrapio?: any;
	resoursePageStrapioItems?: any;
	resourcesPage?: any;
}

export const StaticContext = createContext<StaticContext>({} as any);

export default function StaticContextProvider({
	host,
	children,
}: Props): JSX.Element {
	// State for FAQs
	const [faqData, setFaqData] = useState<any[]>([]);
	const [isLoadingFaqs, setIsLoadingFaqs] = useState<boolean>(true);

	// Fetch FAQs data
	const [{ data: faqResponse, fetching: fetchingFaqs }] = useQuery({
		query: FAQ_ALL_QUERY,
		context: useMemo(
			() => ({
				requestPolicy: "cache-and-network",
				url: process.env.GRAPH_URL_STRAPIO,
			}),
			[],
		),
	});

	useEffect(() => {
		if (!fetchingFaqs && faqResponse?.faqs?.data) {
			setFaqData(faqResponse.faqs.data);
			setIsLoadingFaqs(false);
		}
	}, [fetchingFaqs, faqResponse]);

	// Fetch static context
	const [staticContextResponse] = useQuery<
		GetStaticContextQuery,
		GetStaticContextQueryVariables
	>({
		requestPolicy: "cache-first",
		query: GET_STATIC_CONTEXT,
		pause: !host,
		variables: {
			domain: host,
		},
	});

	// Fetching State and Categories data
	const [resultState] = useQuery({
		query: STATE_QUERY_PAGE,
		context: useMemo(
			() => ({
				requestPolicy: "cache-and-network",
				url: process.env.GRAPH_URL_STRAPIO,
			}),
			[],
		),
	});

	const [resultStateItems] = useQuery({
		query: STATE_QUERY_PAGE_ITEMS,
		context: useMemo(
			() => ({
				requestPolicy: "cache-and-network",
				url: process.env.GRAPH_URL_STRAPIO,
			}),
			[],
		),
	});

	const [resultCategoriesPage] = useQuery({
		query: CATEGORIES_PAGE,
		context: useMemo(
			() => ({
				requestPolicy: "cache-first",
				url: process.env.GRAPH_URL_STRAPIO,
			}),
			[],
		),
	});

	const [resultCategoriesPageItems] = useQuery({
		query: CATEGORIES_PAGE_ITEMS,
		context: useMemo(
			() => ({
				requestPolicy: "cache-first",
				url: process.env.GRAPH_URL_STRAPIO,
			}),
			[],
		),
	});

	const [resultResoursePage] = useQuery({
		query: RESOURCES_QUERY_ITEM,
		context: useMemo(
			() => ({
				requestPolicy: "cache-and-network",
				url: process.env.GRAPH_URL_STRAPIO,
			}),
			[],
		),
	});

	// Resources and Categories Items data processing
	const resoursePageStrapioItems =
		resultResoursePage?.data?.resourcesPageItems?.data || [];
	const resourcesPage = resultResoursePage?.data?.resourcesPage?.data || [];

	const categoriesPageStrapio =
		resultCategoriesPage?.data?.categoriesPage?.data || [];
	const categoriesPageStrapioItems =
		resultCategoriesPageItems?.data?.categoriesCollections?.data || [];

	categoriesPageStrapioItems.forEach((element: any) => {
		let result = element?.attributes?.category.split("_ID_");
		element.attributes.id = result[1].replace(/_/g, "-");
		element.attributes.categoryName = result[0].replace(/_/g, " ");
	});

	const getCategoriesStrapio = (id: string) => {
		return categoriesPageStrapioItems.find(
			(state: any) => state?.attributes?.id === id,
		);
	};

	const statePageStrapio = resultState?.data?.statePage?.data?.attributes || [];
	const statePageStrapioItems =
		resultStateItems?.data?.stateCollections?.data || [];

	statePageStrapioItems.forEach((element: any) => {
		let result = element?.attributes?.state_ID.split("_ID_");
		element.attributes.id = result[1].replace(/_/g, "-");
		element.attributes.stateName = result[0].replace("_", " ");
	});

	const getStateStrapio = (stateID: string) => {
		return statePageStrapioItems.find(
			(state: any) => state?.attributes?.url === stateID,
		);
	};

	// Fetching Pallet Warehouse Page and Items
	const [resultPalletWarehousePage] = useQuery({
		query: PALLET_WAREHOUSE_QUERY,
		context: useMemo(
			() => ({
				requestPolicy: "cache-first",
				url: process.env.GRAPH_URL_STRAPIO,
			}),
			[],
		),
	});

	const palletWarehouseItems =
		resultPalletWarehousePage?.data?.palletWarehouses?.data || [];
	const palletWarehousePageContent =
		resultPalletWarehousePage?.data?.palletWarehousePage?.data?.attributes
			?.Content || [];

	// Context state for Static Data
	const retailers = staticContextResponse.data?.findAllRetailers?.data || [];
	const vocabularyEntries =
		staticContextResponse.data?.getVocabulary?.data || [];
	const states = staticContextResponse.data?.findAllState?.data || [];
	const categories = staticContextResponse.data?.getAllCategories?.data || [];
	const whiteLabelConfig = staticContextResponse.data?.getConfigByDomain?.data;

	// Utilities for categories and states
	const getLinkedCategories = (categoryId: string) => {
		const category = getCategory(categoryId);
		if (!category) return [];
		if (category.linkedCategories.length) {
			return category.linkedCategories
				.filter((linkedCatId) => getCategory(linkedCatId))
				.map((linkedCatId) => getCategory(linkedCatId)!);
		}
		return [category];
	};

	const getLinkedCategoriesByIds = (categoryIds: string[]) => {
		const categories = categoryIds
			.map((cat) => getLinkedCategories(cat))
			.flat();
		const orthodoxCategories = Array.from(
			new Set(categories.map((cat) => cat.id)),
		);
		return getCategoriesByIds(orthodoxCategories);
	};

	const getCategoriesByIds = (categoryIds: string[]) => {
		return categoryIds
			.filter((id) => getCategory(id))
			.map((id) => getCategory(id)!);
	};

	const getCategoryByKeyword = (categoryKeyword: string) => {
		return categories.find((category) => category.keyword === categoryKeyword);
	};

	const getCategory = (categoryId: string) => {
		return categories.find((category) => category.id === categoryId);
	};

	const [isHidden, setIsHidden] = useState(true);

	return (
		<StaticContext.Provider
			value={{
				faqs: faqData,
				host,
				palletWarehouseItems,
				palletWarehousePageContent,
				isHidden,
				setIsHidden,
				statePageStrapio,
				statePageStrapioItems,
				getStateStrapio,
				categoriesPageStrapio,
				categoriesPageStrapioItems,
				getCategoriesStrapio,
				resoursePageStrapioItems,
				resourcesPage,
				retailers: retailers.sort((a, b) => a.name.localeCompare(b.name)),
				retailerTranslator: (retailerId: string) => {
					return (
						retailers.find((retailer) => retailer.id === retailerId)?.name ||
						retailerId
					);
				},
				vocabularyEntries: vocabularyEntries.sort((a, b) => a.index - b.index),
				getVocabularySortedAlphabetically: () => {
					return vocabularyEntries.sort((a, b) =>
						a.title.localeCompare(b.title),
					);
				},
				getVocabularyEntry: (vocabularyEntryId: string | undefined) => {
					return vocabularyEntries.find(
						(vocabularyEntry) => vocabularyEntry.id === vocabularyEntryId,
					);
				},
				translator: (vocabularyEntryId: string) => {
					return (
						vocabularyEntries.find(
							(vocabularyEntry) => vocabularyEntry.id === vocabularyEntryId,
						)?.title || vocabularyEntryId
					);
				},
				categories: categories.sort((a, b) => a.title.localeCompare(b.title)),
				getCategory,
				getCategoryByKeyword,
				getLinkedCategories,
				getLinkedCategoriesByIds,
				getCategoriesByIds,
				translateCategory: (categoryId: string) => {
					return (
						categories.find((category) => category.id === categoryId)?.title ||
						categoryId
					);
				},
				categoryIsParent: (categoryId: string) => {
					const rootCategory = getCategoryByKeyword("CATEGORY");
					const currentCategory = getCategory(categoryId);
					return rootCategory?.id === currentCategory?.parent?.id;
				},
				states: states.sort((a, b) => a.name.localeCompare(b.name)),
				regions: states
					.filter(
						(state, pos, statesArray) =>
							statesArray.findIndex((s) => s.region === state.region) === pos,
					)
					.map((state) => ({ value: state.region, title: state.region })),
				getState: (stateId: string) =>
					states.find((state) => state.id === stateId),
				getStateByAbbreviation: (abbreviation: string) =>
					states.find((state) => state.abbreviation === abbreviation),
				whiteLabelConfig: whiteLabelConfig,
			}}
		>
			{children}
		</StaticContext.Provider>
	);
}
